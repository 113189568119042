import React from "react";
import Helmet from "react-helmet";
import { Flex, Box } from "@rebass/grid";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import Container from "../components/Container";
import H1 from "../components/H1";
import H2 from "../components/H2";
import Button from "../components/Button";

class TagRoute extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges;
    const postLinks = posts.map(post => (
      <Box mb={4}>
        <Link to={post.node.fields.slug}>
          <H2 className="is-size-2">{post.node.frontmatter.title}</H2>
        </Link>
      </Box>
    ));
    const tag = this.props.pageContext.tag;
    const title = this.props.data.site.siteMetadata.title;
    const totalCount = this.props.data.allMarkdownRemark.totalCount;
    const tagHeader = `${totalCount} bericht${
      totalCount === 1 ? "" : "en"
    } over ${tag}`;

    return (
      <Layout>
        <Container>
          <Helmet title={`${tag} | ${title}`} />
          <Flex>
            <Box width={[1, 2 / 3, 1 / 2]} mt={6} mb={5}>
              <H1>{tagHeader}</H1>
            </Box>
            <Box width={[1, 1 / 3, 1 / 2]} />
          </Flex>
          {postLinks}
          <p>
            <Button large as={Link} to="/tags/">
              Bekijk alle tags
            </Button>
          </p>
        </Container>
      </Layout>
    );
  }
}

export default TagRoute;

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
